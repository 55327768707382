import React from "react";

// source: https://www.gcu.edu/

export const GCULogoLong = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      viewBox="0 0 717.1 142.8"
      aria-label="Grand Canyon University"
      style={{ fill: "#fff" }}
    >
      <path
        d="M61.4,27.6c-2.9-13.3-11.6-15.9-21-15.9-16.2,0-26.6,11.2-26.6,31,0,16,7.5,33.5,28.3,33.5A31.3,31.3,0,0,0,52,74.9c2.3-1,3-1.7,3-6.8V58.8c0-7.2-.4-7.9-11.8-8.7V47.7h27v2.4c-5.9.9-6.3,1.2-6.3,8.7v7.1a52.1,52.1,0,0,0,.6,9.6c-6.5,1.8-15,4.2-22.9,4.2-22.2,0-38.2-14.4-38.2-35C3.4,21,22.4,8.3,42.2,8.3A81.5,81.5,0,0,1,62.3,11c.3,3.6.8,9,1.7,16.3Z"
        // style="fill: #61279e"
      />
      <path
        d="M91.5,49.8h5.2c3.7,0,6.6-.5,9-2.5s5.2-7.4,5.2-11.9c0-9.4-6.8-13.2-13.6-13.2a19.5,19.5,0,0,0-5,.4c-.5.2-.8.7-.8,2.5Zm0,15.3c0,9.8.7,10.4,8.1,10.9v2.1h-24V76c7.6-.5,8.3-1.1,8.3-10.9V32.7c0-9.9-.7-10.5-7.9-11V19.6H98.9c5.7,0,10.9.8,14.2,3S119,29,119,34.2c0,7.5-4.6,12.8-11.9,15.7,1.7,2.7,5.4,8.9,8.1,12.9a80.3,80.3,0,0,0,7.5,9.9,11.3,11.3,0,0,0,6.8,4.5l-.2,1.7H128c-10.3-.2-13.5-3.4-16.9-8.3s-6.6-10.8-9.1-15a5.7,5.7,0,0,0-5.6-3.2H91.5Z"
        // style="fill: #61279e"
      />
      <path
        d="M147.3,52.5h15.9l-7.9-22.2h-.2ZM164.5,76l2.9-.3c3.3-.4,3.7-1.4,2.8-4.1s-3.3-9.2-5.8-15.7H146.1c-.9,2.3-3.2,9.3-4.5,13.1s-.9,6.4,3.2,6.8l2.7.2v2.1H128.2V76c5.9-.6,6.6-1.1,9.8-9.1l18.5-47.8,2.1-.9L165,35.4c4.2,11.4,8.1,22.8,11.7,32,2.8,7.6,4,8.2,9.6,8.6v2.1H164.5Z"
        // style="fill: #61279e"
      />
      <path
        d="M242.8,78.8h-2.3L201.4,31.3h-.2v23a126.7,126.7,0,0,0,.8,17.1c.4,3.1,2.8,4.4,8.8,4.6v2.1H189.2V76c4.9-.1,7.3-1.5,7.7-4.6s.7-7.7.7-17.1V34.7c0-6.5-.1-7.9-1.7-10s-4.1-2.8-7.9-3V19.6h13.1l38,45.4h.1V43.5c0-9.5-.3-14.4-.7-17.1s-2.9-4.5-8.8-4.7V19.6h21.6v2.1c-5,.1-7.3,1.5-7.8,4.7a154.2,154.2,0,0,0-.7,17.1Z"
        // style="fill: #61279e"
      />
      <path
        d="M271.9,66.6c0,4.1.3,5.9,1.5,7s3.4,1.5,6.4,1.5c18.9,0,26.2-13,26.2-26.5,0-9.1-1.9-18.7-10.9-23.4-3.6-1.9-8.7-3-15.9-3-4.5,0-5.8.3-6.5.5s-.8.8-.8,2.6Zm9.9-47c10.7,0,19.4,2.2,25.1,7.6a25.8,25.8,0,0,1,7.9,19.1c0,22.2-17,31.8-38.2,31.8H255.9V76c7.7-.5,8.5-1.1,8.5-10.9V32.7c0-9.9-.8-10.5-7.8-11V19.6Z"
        // style="fill: #61279e"
      />
      <path
        d="M401.7,27.1c-3.1-12.1-9.8-15.4-20.1-15.4-18.9,0-27.3,14.3-27.3,30.8,0,20.3,10.4,33.7,27.4,33.7,11.7,0,17.2-5.6,22.4-16.7l2.6.3c-1.4,4.6-3.7,13-5.4,16.9-2.9.8-12.4,3-19.7,3-26.6,0-37.7-18.1-37.7-34.8,0-21.6,16.7-36.6,39.7-36.6,8.9,0,15.9,2.1,18.9,2.7.8,5.8,1.2,10.4,1.9,15.9Z"
        // style="fill: #61279e"
      />
      <path
        d="M427.6,52.5h16l-7.9-22.2h-.2ZM444.9,76l2.8-.3c3.4-.4,3.7-1.4,2.8-4.1s-3.2-9.2-5.7-15.7H426.5c-.9,2.3-3.3,9.3-4.5,13.1s-.9,6.4,3.1,6.8l2.7.2v2.1H408.6V76c5.8-.6,6.5-1.1,9.7-9.1l18.5-47.8,2.1-.9,6.4,17.2c4.2,11.4,8.2,22.8,11.7,32,2.9,7.6,4.1,8.2,9.6,8.6v2.1H444.9Z"
        // style="fill: #61279e"
      />
      <path
        d="M523.2,78.8h-2.4L481.7,31.3h-.1v23c0,9.4.3,14.3.7,17.1s2.9,4.4,8.8,4.6v2.1H469.5V76c5-.1,7.3-1.5,7.8-4.6a157.2,157.2,0,0,0,.7-17.1V34.7c0-6.5-.1-7.9-1.7-10s-4.2-2.8-8-3V19.6h13.2L519.4,65h.2V43.5c0-9.5-.4-14.4-.8-17.1s-2.8-4.5-8.8-4.7V19.6h21.6v2.1c-4.9.1-7.2,1.5-7.7,4.7s-.7,7.6-.7,17.1Z"
        // style="fill: #61279e"
      />
      <path
        d="M563.8,65.1c0,9.8.7,10.4,9.3,10.9v2.1H547.2V76c8.3-.5,9-1.1,9-10.9V54.8c0-1.6-.2-2.4-2-5.4-4-7-7.5-13.5-11.4-20.2s-4.2-6.9-9.5-7.5V19.6H555v2.1l-4.1.5c-2.2.4-2.9,1.2-1.3,4,4,7.5,8.1,15,12.6,22.4,3.8-7.2,7.8-14.6,11.1-22,1.6-3.2.8-4.1-2.6-4.5l-3.6-.4V19.6h19.8v2.1c-6,.5-6.5,1.7-10.2,7.6S569.2,41.8,565.3,49c-1.3,2.5-1.5,3-1.5,4.9Z"
        // style="fill: #61279e"
      />
      <path
        d="M613.9,21.2c-10.6,0-19.6,8.8-19.6,25.7s9.1,29.6,22.2,29.6c10.9,0,19.7-8.4,19.7-25.1,0-19.5-9.4-30.2-22.3-30.2m31,26.8c0,18.8-13,31.5-30.2,31.5s-29.1-13-29.1-30c0-15.1,10.6-31.3,30.7-31.3,15.6,0,28.6,12.1,28.6,29.8"
        // style="fill: #61279e"
      />
      <path
        d="M705.3,78.8H703L663.9,31.3h-.2v23a157.2,157.2,0,0,0,.7,17.1c.5,3.1,2.9,4.4,8.9,4.6v2.1H651.7V76c4.9-.1,7.2-1.5,7.7-4.6s.7-7.7.7-17.1V34.7c0-6.5-.1-7.9-1.7-10s-4.1-2.8-7.9-3V19.6h13.1L701.5,65h.2V43.5a154.2,154.2,0,0,0-.7-17.1c-.4-3.2-2.9-4.5-8.8-4.7V19.6h21.6v2.1c-5,.1-7.3,1.5-7.8,4.7a154.2,154.2,0,0,0-.7,17.1Z"
        // style="fill: #61279e"
      />
      <path
        d="M144.1,99.1v20.6c0,7.8,3.4,11.2,8.1,11.2s8.5-3.5,8.5-11.2V99.1h4.5v20.3c0,10.7-5.6,15.1-13.2,15.1s-12.5-4.1-12.5-14.9V99.1Z"
        // style="fill: #231f20"
      />
      <path
        d="M193.3,134V99.1h5l11.2,17.6a101.6,101.6,0,0,1,6.2,11.4h.1c-.4-4.7-.5-8.9-.5-14.3V99.1h4.3V134H215l-11.1-17.7a106,106,0,0,1-6.5-11.7h-.2c.3,4.5.4,8.6.4,14.5V134Z"
        // style="fill: #231f20"
      />
      <rect
        x="247.7"
        y="99.1"
        width="4.5"
        height="34.91"
        // style="fill: #231f20"
      />
      <path
        d="M288.1,134,276.7,99.1h4.8l5.5,17.2c1.5,4.7,2.8,8.9,3.7,13h.1c1-4,2.4-8.4,4-13l5.9-17.2h4.8L293,134Z"
        // style="fill: #231f20"
      />
      <polygon
        points="347.7 117.6 334.1 117.6 334.1 130.2 349.2 130.2 349.2 134 329.6 134 329.6 99.1 348.4 99.1 348.4 102.8 334.1 102.8 334.1 113.9 347.7 113.9 347.7 117.6"
        // style="fill: #231f20"
      />
      <path
        d="M375.4,99.5a52.4,52.4,0,0,1,8.6-.7c4.8,0,7.9.9,10.1,2.8a8.8,8.8,0,0,1,2.8,6.7c0,4.6-2.9,7.6-6.6,8.9h0c2.7,1,4.3,3.5,5.1,7.1s2,8.2,2.7,9.6h-4.7a59.7,59.7,0,0,1-2.3-8.4c-1-4.8-2.9-6.6-7-6.8h-4.2V134h-4.5Zm4.5,15.9h4.6c4.8,0,7.9-2.6,7.9-6.6s-3.3-6.5-8.1-6.5a18.3,18.3,0,0,0-4.4.4Z"
        // style="fill: #231f20"
      />
      <path
        d="M422.9,128.5a16.5,16.5,0,0,0,8.1,2.3c4.6,0,7.3-2.5,7.3-6s-1.9-5.1-6.6-6.9-9.2-5-9.2-9.9,4.5-9.5,11.3-9.5a15.6,15.6,0,0,1,7.7,1.7l-1.2,3.7a14.5,14.5,0,0,0-6.7-1.7c-4.7,0-6.6,2.9-6.6,5.2s2.2,4.9,7,6.8,8.9,5.1,8.9,10.2-4,10.1-12.2,10.1a18.2,18.2,0,0,1-8.9-2.2Z"
        // style="fill: #231f20"
      />
      <rect
        x="469.4"
        y="99.1"
        width="4.5"
        height="34.91"
        // style="fill: #231f20"
      />
      <polygon
        points="508.7 102.9 498 102.9 498 99.1 523.9 99.1 523.9 102.9 513.2 102.9 513.2 134 508.7 134 508.7 102.9"
        // style="fill: #231f20"
      />
      <path
        d="M557.3,134V119.2l-11-20.1h5.1l4.9,9.6c1.3,2.6,2.4,4.8,3.5,7.2h.1c1-2.3,2.1-4.6,3.5-7.2l5-9.6h5.1l-11.7,20V134Z"
        // style="fill: #231f20"
      />
      <path
        d="M586.3,94.2a4.9,4.9,0,0,1,5.1,4.9,5,5,0,0,1-5.1,5,5,5,0,0,1-5.1-5,5,5,0,0,1,5.1-4.9Zm0,1a4,4,0,0,0,0,7.9,4,4,0,0,0,0-7.9Zm-.8,6.5h-1.1v-5h1.9a2.5,2.5,0,0,1,1.7.4,1.2,1.2,0,0,1,.5,1,1.4,1.4,0,0,1-1,1.2h0c.4.1.7.5.8,1.2a4.9,4.9,0,0,0,.4,1.2h-1.2a2.4,2.4,0,0,1-.4-1.2.9.9,0,0,0-1-.8h-.6Zm0-2.8h.6c.6,0,1.1-.2,1.1-.7s-.3-.8-1-.8h-.7Z"
        // style="fill: #231f20"
      />
    </svg>
  );
};
